import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import WizardFormExternal from '../../components/WizardFormExternal';
import Step1Insured from '../../components/common/InsuredForms/Step1';
import { connect, useDispatch } from 'react-redux';
import { mainHome } from '../../utils/constants';
import { MSG_025 } from '../../utils/messages';
import { configSuccess } from '../../utils/';
import { notification, Row, Typography } from 'antd';
import { requestAddUser } from '../../redux/actions/usersActions';

function ExternalInsured({ ...props }) {
  const { insuredExternal, location } = props;
  const dispatch = useDispatch();
  const insuranceCarrier =
    typeof location.state !== 'undefined' ? location.state.insuranceCarrier : false;
  const { Title } = Typography;
  const [isRegistering, setIsRegistering] = useState(false);

  const formsInsured = [
    {
      title: 'Información personal',
      content: (
        <Step1Insured
          type="autoRegister"
          insuredExternal={insuredExternal.data && insuredExternal.data.searchParams}
          isAdmin={false}
          insuranceCarrier={insuranceCarrier}
          isRegistering={isRegistering}
          externalRegister={true}
        />
      )
    }
  ];

  const userAddSuccess = message => {
    notification.success({
      message: message,
      ...configSuccess
    });
    setIsRegistering(false);
    notification.success({
      message: MSG_025,
      ...configSuccess
    });
    props.history.push(`${mainHome}`);
  };

  const userAddFail = () => {
    setIsRegistering(false);
    //props.history.push(`${mainHome}`);
  };

  const addUser = async (user, type) => {
    let u = JSON.stringify(user);
    setIsRegistering(true);

    try {
      dispatch(
        requestAddUser({
          params: { user: u, userType: type },
          userAddSuccess: userAddSuccess,
          userAddFail
        })
      );
    } catch (error) {
      setIsRegistering(false);
      console.error(error);
    }
  };

  return (
    <Row justify="center" className="no-float text-left" xs={{ span: 24 }} lg={{ span: 24 }}>
      <Title level={3}>Registro de asegurado</Title>
      <WizardFormExternal
        jest-id="WizardExternalInsured"
        isRegistering={isRegistering}
        dataToPage={addUser}
        formsExternal={formsInsured}
      />
    </Row>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.info,
    insuredExternal: state.insuredExternal
  };
}

export default withRouter(connect(mapStateToProps, {})(ExternalInsured));
