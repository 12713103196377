import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import WizardFormExternal from '../../components/WizardFormExternal';
import Step1Moral from '../../components/common/MoralBuyerForms/Step1';
import Step2Moral from '../../components/common/MoralBuyerForms/Step2';
import Step3Moral from '../../components/common/MoralBuyerForms/Step3';
import Step1Personal from '../../components/common/PersonalBuyerForms/Step1';
import Step2Personal from '../../components/common/PersonalBuyerForms/Step2';
import Step3Personal from '../../components/common/PersonalBuyerForms/Step3';
import { sendNotification } from 'helpers/helperNotifications';
import { connect, useDispatch } from 'react-redux';
import { setWizardForm, cleanWizardForm } from '../../redux/actions/wizardFormActions';
import { mainHome, toLogin } from '../../utils/constants';
import { configSuccess } from '../../utils/';
import { notification, Row, Select, Col, Form } from 'antd';
import { requestMarkets } from '../../redux/actions/catalogsActions';
import { buildExternalBuyerReg, registerExternalBuyerCompany } from '../../services/users';
import { layoutInputs } from '../../helpers/helperForms';
import { objectHasProperty } from '../../utils';
import STitle from '../../components/common/STitle';

import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';
import { userRolesIds } from 'models/enums';
import { messageService } from 'services/message';
const { Option } = Select;
function ExternalB({ markets, user, handleShowModal, ...props }) {
  const { match, history, form } = props;
  const { push } = history;
  const { params } = match;
  const { token } = params;
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const [isRegistering, setIsRegistering] = useState(false);
  const buyerTypeString = useRef('');
  const responseComplete = useRef(false);
  const [data, setData] = useState({ isLoading: false, data: false });
  const [buyerType, setBuyerType] = useState('Buyers/BuyerCompany');
  const [currentProfile, setCurrentProfile] = useState(userRolesIds.MORAL_BUYER);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const { t } = useTranslation();
  const topLevelProofs = useRef([])


  let companyInfoInside = useRef({});
  let workInfo = useRef({});
  let contactInfo = useRef({});

  useEffect(() => {
    cleanWizardForm();
  }, [cleanWizardForm]);

  useEffect(() => {
    const listener = messageService.getMessage().subscribe(message => {
      if(message.type === 'proofs' && message.locallyProofs?.length){
        topLevelProofs.current = message.locallyProofs;
      }
    });
    return () => {
      listener.unsubscribe();      
    }
  }, [])
  

  useEffect(() => {
    const getMarkets = () => {
      if (typeof user !== 'undefined' && user.groupName) {
        dispatch(requestMarkets());
      }
    };

    getMarkets();
  }, [user]);

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        setData({ isLoading: true });
        const response = await buildExternalBuyerReg(token, true);

        if (typeof response !== 'undefined' && response.status === 200) {
          if (mounted) {
            setData({
              isLoading: false,
              data: response.data.data
            });
            responseComplete.current = true;
            return false;
          }
        } else if (typeof response !== 'undefined' && response.status === 400) {
          push(`${toLogin}`);
        }
        if (mounted) setData({ isLoading: false });
      } catch (error) {
        if (mounted) setData({ isLoading: false });
      }
    };
    if (mounted && !isRegistering) getData();
    return () => {
      mounted = false;
    };
  }, [token, push, isRegistering]);

  useEffect(() => {
    let isMounted = true;
    if (data && objectHasProperty(data, 'data') && !isRegistering) {
      let setForms = {};

      companyInfoInside.current = data.data.companyInfo;
      contactInfo.current = data.data.contactInfo;
      workInfo.current = data.data.workInfo;

      const isMoralBuyer = buyerType === 'Buyers/BuyerCompany';

      if (contactInfo.current) {
        if (isMoralBuyer) {
          setForms = {
            Step0: {
              ...companyInfoInside.current
            },
            Step1: {
              ...contactInfo.current
            },
            Step2: { ...workInfo.current }
          };
        } else {
          setForms = {
            Step0: {
              personalInfo: {
                ...companyInfoInside.current,
                email: contactInfo.current.email,
                givenName: contactInfo.current.givenName,
                surname: contactInfo.current.surname,
                lastname: contactInfo.current.lastname
              },
              email: contactInfo.current.email
            },
            Step1: { contactInfo: { ...contactInfo.current } },
            Step2: { workInfo: { ...workInfo.current } }
          };
        }

        if (isMounted && !isRegistering)
        dispatch(
            setWizardForm({
              forms: setForms,
              userData: {
                [isMoralBuyer ? 'companyInfo' : 'personalInfo']: companyInfoInside.current,
                contactInfo: {
                  ...companyInfoInside.current,
                  email: contactInfo.email,
                  givenName: contactInfo.givenName,
                  surname: contactInfo.surname,
                  lastname: contactInfo.lastname
                },
                workInfo: { ...workInfo.current }
              }
            })
          );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, buyerType, isRegistering, dispatch]);

  const addUser = async objUser => {
    setIsRegistering(true);
    setIsBlocked(true);
    let formattedUser = {};
    try {
      let response;
      if (buyerType === 'Buyers/BuyerCompany') {
        var result = new Date();
        result.setDate(result.getDate() + 60);
        formattedUser = {
          ...objUser,
          companyInfo: {
            ...objUser.companyInfo,
            validFrom: new Date().toISOString(),
            validTo: result.toISOString()
          }
        };
      } else if (buyerType === 'Buyers/Buyer') {
        let firsPosition = { ...objUser.personalInfo, ...objUser.companyInfo };
        var result = new Date();
        result.setDate(result.getDate() + 60);
        formattedUser = {
          ...objUser,
          personalInfo: {
            ...firsPosition,
            validFrom: new Date().toISOString(),
            validTo: result.toISOString()
          }
        };
      }
      if (user && user.groupName) {
        let getMarkets = markets.filter(m => m.id);
        getMarkets = getMarkets.map(gm => {
          return {
            id: gm.id,
            name: gm.name
          };
        });

        formattedUser = {
          ...formattedUser,
          workInfo: {
            ...formattedUser.workInfo,
            insuranceCarriers: getMarkets
          }
        };
      }

      if(topLevelProofs?.current?.length){
        formattedUser = {
          ...formattedUser,
          proofs: topLevelProofs.current
        };
      }

      response = await registerExternalBuyerCompany(token, formattedUser, buyerType);

      if (typeof response !== 'undefined' && response.status === 200) {
        setIsBlocked(false);

        notification.success({
          message: t('MSG_025'),
          ...configSuccess
        });

        setIsRegistered(true);

        push(`${mainHome}`);
      } else {
        setIsBlocked(false);
        setIsRegistered(false);
        if (response?.data?.message === t('MSG_164')) {
          sendNotification('info', response.data.message);
        } else {
          sendNotification('error', response.data.message);
        }
      }
    } catch (error) {
      setIsBlocked(false);
      setIsRegistering(false);
      setIsRegistered(false);
    }
    return false;
  };

  const completeDataRequired = () => {
    return data.data;
  };
  

  const WizardWithContent = () => {
    const [locallyProofs, setLocallyProofs] = useState([]);
    const commonProps = {
      locallyProofs, 
      setLocallyProofs,
    };
    
    useEffect(() => {
      if(locallyProofs?.length){
        messageService.sendMessage({type: 'proofs', locallyProofs});
      }
    }, [locallyProofs])
    

    if (data && objectHasProperty(data, 'data')) {
      const formsMoralBuyer = [
        {
          title: 'Información del negocio',
          content: <Step1Moral  {...commonProps} externalRegister currentProfile={currentProfile} handleShowModal={handleShowModal} />
        },
        {
          title: 'Información del apoderado legal',
          content: <Step2Moral {...commonProps}  externalRegister type="autoRegister" isAdmin={false} isRegistering={isRegistering} saving={isBlocked}/>
        }
      ];

      const formsPersonalBuyer = [
        {
          title: 'Información personal',
          content: (
            <Step1Personal  {...commonProps} externalRegister isAdmin={false} handleShowModal={handleShowModal} />
          )
        },
        {
          title: 'Información de contacto',
          content: <Step2Personal {...commonProps} externalRegister isAdmin={false} type="autoRegister"/>
        },
        {
          title: 'Información laboral',
          content: (
            <Step3Personal
            {...commonProps}
              externalRegister
              isAdmin={false}
              isRegistering={isRegistering}
              saving={isBlocked}
              currentProfile={currentProfile}
            />
          )
        }
      ];

      let forms = buyerType === 'Buyers/Buyer' ? formsPersonalBuyer : formsMoralBuyer;

      return (
        <Col className="no-float text-left" xs={{ span: 24 }} lg={{ span: 24 }}>
          <STitle titleText={t('externalbuyer.userregistration')} level={3} />
          <br />
          <Row>
            <Col sm={4} md={4} lg={4} xl={4}>
              <p style={{ fontSize: '14px' }}> Tipo de usuario: </p>
            </Col>
            <Col {...layoutInputs}>
              <p style={{ fontSize: '14px' }}>Comprador</p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={4} md={4} lg={4} xl={4}>
              <p style={{ fontSize: '14px' }}> Tipo de comprador: </p>
            </Col>
            <Col {...layoutInputs}>
              <Form.Item>
                {getFieldDecorator('selectType', {
                  initialValue: 'Buyers/BuyerCompany'
                })(
                  <Select
                    style={{ width: '100%' }}
                    onChange={v => {
                      buyerTypeString.current = v;
                      if(v === 'Buyers/BuyerCompany') setCurrentProfile(userRolesIds.MORAL_BUYER);
                      else setCurrentProfile(userRolesIds.PERSONAL_BUYER);
                      setBuyerType(v);
                    }}
                  >
                    <Option value="Buyers/BuyerCompany">Comprador Moral</Option>
                    <Option value="Buyers/Buyer">Comprador Físico</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <br />
          <br />
          <WizardFormExternal
            isRegistering={isRegistering}
            dataToPage={addUser}
            formsExternal={forms}
            hasBeenRegistered={isRegistered}
            isFromExternalBuyer={true}
          />
        </Col>
      );
    }
  };

  return (
    <>
      {data.isLoading && <Loader isPageLoader={true} />}
      {!data.isLoading && data && completeDataRequired() && <WizardWithContent />}
      {!data.isLoading && !data && <h2>{t('noDataRecovery')}</h2>}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.info,
    markets: state.catalogs.markets,
    insuredExternal: state.insuredExternal
  };
}

const ExternalBuyer = Form.create({ name: 'al-buyer-user' })(ExternalB);

export default withRouter(connect(mapStateToProps, {})(ExternalBuyer));
